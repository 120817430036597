<template>
	<div>

		<template v-if="type == 2 && show">
			<div v-if="$store.state.userInfo.lv == 1 && dataState === ''" class="card-list margin-bottom-xxl">
				<div class="line flex-btw">
					<div class="title">{{$t('profile.name')}}</div>
					<input v-model="reqData.name" placeholder="请输入姓名" />
				</div>
				<div class="line flex-btw">
					<div class="title">{{$t('profile.phoneNumber')}}</div>
					<input type="number" maxlength="11" v-model="reqData.phone" placeholder="请输入手机号" />
				</div>
				<div class="line flex-btw">
					<div class="title">{{$t('profile.company')}}</div>
					<input v-model="reqData.title" placeholder="请输入公司名称" />
				</div>
				<div class="line flex-btw">
					<div class="title">{{$t('profile.companyAddress')}}</div>
					<textarea v-model="reqData.address" placeholder="请输入公司地址"></textarea>
				</div>
				<div class="flex-center " style="width: 100%;">
					<div class="form-btn flex-center" @click="apply">{{$t('profile.submit')}}</div>
				</div>
			</div>
			<div v-else>
				<div class="card-list"
					v-if="$store.state.userInfo.lv != 1 || $store.state.userInfo.lv == 99 || dataState==0"
					:class=" $store.state.userInfo.lv != 1 || $store.state.userInfo.lv == 99 || dataState==0? ' ' :'margin-bottom-xxl'">
					<div class="card border-bottom" @click="toPage('Cart')">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="cartImg" />
							</div>
							<div class="text-center padding-top">{{$t('profile.cart')}}</div>
						</div>
					</div>
					<div class="card border-bottom border-left" @click="toPage('Order')">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="receiptImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.receipt')}}</div>
						</div>
					</div>
					<div class="card" @click="toPage('Collection')">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="collectImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.collection')}}</div>
						</div>
					</div>

					<div class="card border-left" @click="goProList(3)">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="recordImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.record')}}</div>
						</div>
					</div>
				</div>
				<div class="card-list margin-bottom-xxl" v-show="$store.state.userInfo.lv == 99">
					<div class="card border-bottom">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="orderImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.order')}}</div>
						</div>
					</div>
					<div class="card border-bottom border-left">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="informationImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.dataCenter')}}</div>
						</div>
					</div>
					<div class="card" @click="toPage('Customer')">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="customerImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.customer')}}</div>
						</div>
					</div>

					<div class="card border-left" @click="toPage('Message')">
						<div>
							<div class="flex-center">
								<img class="item-icon" :src="messageImg" alt="" />
							</div>
							<div class="text-center padding-top">{{$t('profile.message')}}</div>
						</div>
					</div>
				</div>
			</div>

		</template>
		<template v-if="type == 1">
			<OldCard></OldCard>
		</template>
	</div>
</template>

<script>
	import OldCard from './oldUserCard.vue'
	import customer from '@/assets/img-v3/new-user-center/customer3x.png';
	import order from '@/assets/img-v3/new-user-center/order3x.png';
	import cart from '@/assets/img-v3/new-user-center/cart3x.png';
	import receipt from '@/assets/img-v3/new-user-center/buy3x.png';
	import collect from '@/assets/img-v3/new-user-center/f3x.png';
	import information from '@/assets/img-v3/new-user-center/data3x.png';
	import record from '@/assets/img-v3/new-user-center/record3x.png';
	import message from '@/assets/img-v3/new-user-center/message3x.png';

	import {
		warrant,
		findWarrant

	} from '@/api'
	import {
		Toast
	} from 'vant'

	export default {
		components: {
			OldCard
		},
		props: {
			type: {
				type: [String, Number],
				default: 2
			}
		},
		data() {
			return {
				customerImg: customer,
				orderImg: order,
				messageImg: message,
				recordImg: record,
				informationImg: information,
				collectImg: collect,
				receiptImg: receipt,
				cartImg: cart,
				reqData: {
					name: '',
					phone: '',
					title: '',
					address: '',
					shopUuid: this.$store.state.shopUuid,
					wxOpenId: this.$store.state.userInfo.openid
				},
				dataState: '' ,//可以申请
				show:false
			}
		},
		mounted() {
			this.getUserInfo()

		},
		methods: {
			getUserInfo() {
				findWarrant({
					wxOpenId: this.$store.state.userInfo.openid,
					shopUuid: this.$store.state.shopUuid
				}).then(resp => {
					this.show = true
					if (resp.state == 100 && resp.items) {
						if (resp.items.dataState === 0) {
							this.dataState = 0
						} else {
							this.dataState = resp.items.dataState
						}
					} else {
						this.dataState = ''
					}
				})
			},
			apply() {
				if (!this.reqData.name) {
					Toast('请输入姓名')
					return
				}
				if (!this.reqData.phone) {
					Toast('请输入电话')
					return
				}
				warrant(this.reqData).then(res => {
					if (res.state == 100) {
						Toast('提交成功')
						this.getUserInfo()
					}
				})
			},
			toPage(name) {
				this.$router.push({
					name: name,
					query: {
						shopUuid: this.$store.state.shopUuid
					},
				});
			},
			goProList(type) {
				this.$router.push({
					name: "ProList",
					query: {
						type,
						shopUuid: this.$store.state.shopUuid
					},
				});
			},

		}
	}
</script>

<style lang="less">
	textarea::-webkit-input-placeholder {
		/* placeholder颜色  */
		color: #C8C8C8;
	}

	textarea:-moz-placeholder {
		color: #C8C8C8;
	}

	textarea::-moz-placeholder {
		color: #C8C8C8;
	}

	textarea::-ms-input-placeholder {
		color: #C8C8C8;
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.padding-top {
		padding-top: 10px;
	}

	.card-list {
		margin-top: 34px;
		box-shadow: 0px 0px 12.5px 0px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		background: #ffffff;
		padding: 20px;

		.card {
			width: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 30px;

			.item-icon {
				width: 50px;
			}

			.text-center {
				text-align: center;
			}
		}

		.line {
			width: 288px;
			margin-top: 10px;

			.title {
				width: 60px;
				font-size: 15px;
				font-family: HarmonyOS Sans SC;
				font-weight: 500;
				color: #323232;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 40px;
			}

			input {
				width: 210px;
				height: 40px;
				background: #F5F5F5;
				border-radius: 10px;
				border: none;
				padding-left: 10px;
			}

			textarea {
				width: 200px;
				height: 80px;
				background: #F5F5F5;
				border-radius: 10px;
				border: none;
				padding: 10px;
				margin-left: 5px;
				resize: none;
			}
		}

		.form-btn {
			width: 250px;
			height: 35px;
			background: linear-gradient(90deg, #36C9FF 0%, #9308FF 100%);
			border-radius: 18px;
			color: #ffffff;
			margin-top: 28px;
		}
	}

	.border-left {
		border-left: 1px solid #eee !important;
	}

	.border-right {
		border-right: 1px solid #eee;
	}

	.border-top {
		border-top: 1px solid #eee;
	}

	.border-bottom {
		border-bottom: 1px solid #eee;
	}
</style>
