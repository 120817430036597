<template>
	<div>
	<div class="item" @click="toPage('Cart')">
		<div class="item-left">
			<img class="item-icon" src="@/assets/img/profile/cart.png" alt="" />
			<span>我的购物车</span>
		</div>
		<van-icon class="item-right" name="arrow" />
	</div>

	<div class="item" @click="toPage('Order')">
		<div class="item-left">
			<img class="item-icon" src="@/assets/img/profile/receipt.png" alt="" />
			<span>我的进货单</span>
		</div>
		<van-icon class="item-right" name="arrow" />
	</div>
	<div class="item" @click="toPage('Collection')">
		<div class="item-left">
			<img class="item-icon" src="@/assets/img/profile/shoucang.png" alt="" />
			<span>我的收藏</span>
		</div>
		<van-icon class="item-right" name="arrow" />
	</div>

	<div class="item" style="margin-bottom: 120px" @click="goProList(3)">
		<div class="item-left">
			<img class="item-icon" src="@/assets/img/profile/zuji.png" alt="" />
			<span>浏览记录</span>
		</div>
		<van-icon class="item-right" name="arrow" />
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			toPage(name) {
				this.$router.push({
					name: name,
					query: {
						shopUuid: this.$store.state.shopUuid
					},
				});
			},
			goProList(type) {
				this.$router.push({
					name: "ProList",
					query: {
						type,
						shopUuid: this.$store.state.shopUuid
					},
				});
			},
		}
	}
</script>

<style lang="less">
	.item {
		height: 59px;
		background: #ffffff;
		margin: 7px 0;
		padding: 0 26px 0 23px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.item-left {
			display: flex;
			justify-content: center;
			align-items: center;

			&>img {
				width: 30px;
				height: 30px;
				margin-right: 12px;
			}

			&>span {
				font-size: 15px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #323232;
			}
		}

		.item-right {
			width: 8px;
			height: 12px;
			color: #a3a3a3;
		}
	}
</style>
