<template>
	<div class="profile-container">
		<!-- <TopNavBar /> -->
		<div class="body">
			<div class="userinfo">
				<img v-if="swipeList" :src="swipeList" class="bg-img" />
				<div v-else class="default-img"></div>
			</div>
			<div class="header-card">
				<div class="header-content">
					<div class="flex-btw">
						<div class="flex">
							<img  :src="$store.state.userInfo && $store.state.userInfo.headimgurl" referrerpolicy="no-referrer"/>
							<div class="flex-center">
								<div class="margin-left">
									<div class="title">{{$store.state.userInfo.nickname}}</div>
									<div class="cu-btn margin-top-sm">
										<div v-if="$store.state.userInfo.lv != 1 && $store.state.userInfo.lv != 99">{{$t('profile.lookPrice')}}</div>
										<div v-if="$store.state.userInfo.lv == 1">{{$t('profile.consumer')}}</div>
										<div v-if="$store.state.userInfo.lv == 99">{{$t('profile.manager')}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex-center">
							<div class="">
								<div class="cu-btn">{{$t('profile.exit')}}</div>
								<div class="cu-btn margin-top-sm bg-blue">{{$t('profile.personal')}}</div>
							</div>
						</div>
					</div>
					<div class="flex-center margin-top-xl" v-if="$store.state.userInfo.lv == 99">
						<div class="flex" style="width: 100%;">
							<div style="width: 50%;">
								<div class="text-center text-bold">{{scanTotal}}</div>
							    <div class="text-center text-gray padding-top">{{$t('profile.scanTotal')}}</div>
							</div>
							<div style="width: 50%;" class="border-left-profile">
								<div class="text-center text-bold">{{customerTotal}}</div>
								<div class="text-center text-gray padding-top">{{$t('profile.customerTotal')}}</div>
							</div>
						</div>
					</div>
				</div> 
				<ProCard></ProCard>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getFileList,
		getTodayScanCount,
		listWarrant,
		findUserInfo
	} from "@/api";
	import TopNavBar from "./components/TopNavBar.vue";
	import headerImg from "../../assets/img/proDetails/header.png";
	import ProCard from './components/ProCard.vue';
	import { Toast } from 'vant'
	export default {
		name: "Profile",
		data() {
			return {
				headerImg: headerImg,
				swipeList: "",
				typeName: "deUserCenter",
				scanTotal:0,//今日扫码量
				customerTotal:0,//申请客户数量
			};
		},
		components: {
			TopNavBar,
			ProCard
		},
		created() {
			this.getFileList();
			findUserInfo({
				wxOpenId: this.$store.state.userInfo.openid,
				shopUuid: this.$store.state.shopUuid
			}).then(resp =>{
				
				if (resp.state == 100 && resp.items) {
					localStorage.setItem('userInfo', JSON.stringify(resp.items))
					this.$store.commit('SET_USER_INFO', resp.items)
					localStorage.setItem('appUser', resp.items.uuid)
					window.wx_uuid = resp.items.uuid;
				}
			})
		},
		methods: {
			// 获取列表数据
			getFileList() {
				const params = {
					page: 0,
					pageSize: 0,
					typeName: this.typeName,
					parentUuid: this.$store.state.shopUuid,
				};

				getFileList(params).then((res) => {
					if (res.state == 100 && res.items.length != 0) {
						this.swipeList = res.items[0].filePath;
					}
				});
				
				getTodayScanCount({shopUuid: this.$store.state.shopUuid}).then(res =>{
					if(res.state === 100){
						this.scanTotal = res.total
					}
				})
				listWarrant({shopUuid: this.$store.state.shopUuid}).then(res =>{
					if(res.state === 100){
						this.customerTotal = res.items.total;
					}
				})
			},
			errorImg(e) {
				e.target.src = this.headerImg;
			},
		},
	};
</script>

<style lang="less" scoped>
	.profile-container {
		.border-left-profile{
			border-left: 1px solid #4a4a4a;
		}
		.body {
			.userinfo {
				position: relative;
				height: 250px;
				width: 100%;
			}
			.userinfo::after {
				content: '';
				width: 100%;
				height: 250px;
				position: absolute;
				z-index: 0;
				top: 0;
				background-image: url('~@/assets/img/contact/c-bg-img.png');
				background-size: 100% 100%;
				left: 0;
			}
			.bg-img {
				width: 100%;
				height: 250px;
			}
			
			.default-img {
				width: 100%;
				height: 250px;
				background: #1989fa;
			}
			.header-card{
				padding:25px;
				position: absolute;
				top: 122px;
				width: 100%;
				z-index: 99;
				.header-content{
					background: #000;
					background-image: url('~@/assets/img-v3/new-user-center/user-crad-bg.png');
					background-size: 100% 100%;
					box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
					width: 100%;
					height: 100%;
					border-radius: 10px;
					padding: 25px 13px 40px 16px;
					
					color: #ffffff;
					img{
						height: 65px;
						width: 65px;
						border-radius: 50%;
					}
					.title{
						font-size: 16px;
						font-weight: 500;
					}
					.cu-btn{
						width: 65px;
						height: 18px;
						background: #FF7200;
						border-radius: 5px;
						text-align: center;
						line-height: 18px;
						font-size: 13px;
					}
				}
			}

			// .head-img-box {
			// 	height: 233px;
			// 	display: flex;
			// 	flex-direction: column;
			// 	justify-content: center;
			// 	align-items: center;

			// 	.backImg {
			// 		width: 100vw;
			// 		height: 233px;
			// 		z-index: 0;
			// 		top: 0;
			// 		left: 0;
			// 	}

			// 	&>.head-img {
			// 		width: 72px;
			// 		height: 72px;
			// 		background: #ffffff;
			// 		border-radius: 50%;
			// 		margin-bottom: 14px;
			// 		z-index: 1;
			// 	}

			// 	&>span {
			// 		font-size: 15px;
			// 		font-family: Source Han Sans CN;
			// 		font-weight: 400;
			// 		color: #000000;
			// 		z-index: 1;
			// 	}
			// }
		}
	}
</style>
